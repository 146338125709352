<template>
  <div v-if="examInfo">
    <el-dialog
      :visible.sync="phoneDialog"
      :show-close="false"
      :close-on-click-modal="false"
      width="334px"
    >
      <div class="phone_box">
        <div class="phone_top">考试结束</div>
        <div class="phone_title">{{ examInfo.examName }}</div>
        <!-- <div class="phone_time">
          考试时间：{{
            examInfo.startTime !== null && examInfo.endTime !== null
              ? examInfo.startTime
              : "不限时"
          }}
        </div>
        <div class="phone_content">
          考试时长：
          <span style="color: #ff1d1d">{{ examInfo.duration }}分钟</span>
        </div> -->
        <el-button class="phone_btn"> 考试完成 </el-button>
        <!-- <div class="phone_shuaxin">
          点击 <span @click="shuaxin">刷新</span> 进行考试
        </div> -->
        <el-button class="phone_btn" style="margin-top:20px;background:#ffffff;color:#333333;border:1px solid #999999" @click="logout()"> 关闭页面 </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phoneDialog: true,
      examInfo: {
        examName: "",
        startTime: "",
        duration: "",
        status: "",
      },
      total: "",
      queryInfo: {
        examType: null,
        startTime: null,
        endTime: null,
        examName: null,
        pageNo: 0,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getExamInfo();
  },
  methods: {
    getExamInfo() {
      this.$http.post(this.API.getExamInfo, this.queryInfo).then((resp) => {
        console.log(resp);
        if (resp.data.code === 200) {
          this.examInfo = resp.data.data[2];
          this.loading = false;
        }
      });
    },
    //退出登录
    async logout() {
      const resp = await this.$http.get(this.API.logout);
      if (resp.data.code === 200) {
        //退出成功
        window.localStorage.removeItem("authorization");
        //右侧提示通知
        this.$notify({
          title: "Tips",
          message: "注销成功",
          type: "success",
          duration: 2000,
        });
        await this.$router.push("/");
      } else {
        //异常
        this.$notify({
          title: "Tips",
          message: "注销失败,服务器异常",
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  background: url("../assets/imgs/phoneLoding.png") no-repeat center;
  background-size: 100% 100%;
  height: 516px;
  box-shadow: none;
  position: relative;
  top: -80px;
}
.phone_box {
  position: relative;
  top: 100px;
}
.phone_top {
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
}
.phone_shuaxin {
  color: #999999;
  margin: auto;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  span {
    display: inline-block;
    background-color: #4284fd;
    color: #ffffff;
    border-radius: 3px;
    width: 45px;
    height: 27px;
    line-height: 27px;
    font-weight: 500;
  }
}
.phone_content {
  margin: auto;
  text-align: center;
  color: #333333;
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
}
.phone_btn {
  width: 250px;
  height: 41px;
  line-height: 41px;
  color: #ffffff;
  background-color: #4284fd;
  border-radius: 20px;
  text-align: center;
  margin: auto;
  margin-top: 50px;
  font-size: 18px;
  padding: 0;
  display: block;
  font-size: 18px;
  font-weight: 400;
}
.phone_btn2 {
  width: 250px;
  height: 41px;
  line-height: 41px;
  color: #ffffff;
  border-radius: 20px;
  text-align: center;
  margin: auto;
  margin-top: 18px;
  font-size: 18px;
  padding: 0;
  display: block;
  span {
    font-weight: 400;
  }
}

.phone_time {
  height: 41px;
  width: 280px;
  color: #4284fd;
  font-size: 17px;
  background-color: #e2ecff;
  margin: auto;
  border-radius: 20px;
  text-align: center;
  line-height: 41px;
  font-weight: 500;
  margin-top: 25px;
}
.phone_title {
  color: #333333;
  font-size: 21px;
  text-align: center;
  width: 230px;
  margin: auto;
  margin-top: 80px;
  font-weight: 500;
  line-height: 1.5;
}
</style>